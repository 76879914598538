import routerOptions0 from "/builds/OptiLynx/marketing/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@18.19.43_@upstash+redis@1.34.0_eslint@8.5_26c4a0e407f3a40accba65c907e7d1f1/node_modules/nuxt/dist/pages/runtime/router.options";
import routerOptions1 from "/builds/OptiLynx/marketing/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}