import revive_payload_client_92eS60c3vX from "/builds/OptiLynx/marketing/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@18.19.43_@upstash+redis@1.34.0_eslint@8.5_26c4a0e407f3a40accba65c907e7d1f1/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_N5W30HGqne from "/builds/OptiLynx/marketing/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@18.19.43_@upstash+redis@1.34.0_eslint@8.5_26c4a0e407f3a40accba65c907e7d1f1/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_hRGwEYxEnd from "/builds/OptiLynx/marketing/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@18.19.43_@upstash+redis@1.34.0_eslint@8.5_26c4a0e407f3a40accba65c907e7d1f1/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_QyOud9SEIV from "/builds/OptiLynx/marketing/node_modules/.pnpm/nuxt-site-config@2.2.15_magicast@0.3.4_rollup@4.20.0_vite@5.3.5_@types+node@18.19.43_sa_bde45323e7d4e8e956263895158018f3/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_UU6mgFsJSp from "/builds/OptiLynx/marketing/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@18.19.43_@upstash+redis@1.34.0_eslint@8.5_26c4a0e407f3a40accba65c907e7d1f1/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_emXItkDaLZ from "/builds/OptiLynx/marketing/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@18.19.43_@upstash+redis@1.34.0_eslint@8.5_26c4a0e407f3a40accba65c907e7d1f1/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_RivNmP2y4E from "/builds/OptiLynx/marketing/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@18.19.43_@upstash+redis@1.34.0_eslint@8.5_26c4a0e407f3a40accba65c907e7d1f1/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_cKgY96SZQ9 from "/builds/OptiLynx/marketing/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@18.19.43_@upstash+redis@1.34.0_eslint@8.5_26c4a0e407f3a40accba65c907e7d1f1/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/builds/OptiLynx/marketing/.nuxt/components.plugin.mjs";
import prefetch_client_FUMnFNe26i from "/builds/OptiLynx/marketing/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@18.19.43_@upstash+redis@1.34.0_eslint@8.5_26c4a0e407f3a40accba65c907e7d1f1/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import titles_ejsiE6XLSp from "/builds/OptiLynx/marketing/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.16_h3@1.12.0_magicast@0.3.4_rollup@4.20.0_vite@5.3.5_@types+node@1_98a1ad662109603a6ebb29ecd3675982/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.js";
import defaults_HHs2JsToHg from "/builds/OptiLynx/marketing/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.16_h3@1.12.0_magicast@0.3.4_rollup@4.20.0_vite@5.3.5_@types+node@1_98a1ad662109603a6ebb29ecd3675982/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaults.js";
import siteConfig_sGgetzQO2y from "/builds/OptiLynx/marketing/node_modules/.pnpm/nuxt-seo-experiments@4.0.0_magicast@0.3.4_rollup@4.20.0_vite@5.3.5_@types+node@18.19.43_62e689f114e9e49817893ee92df59563/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.js";
import inferSeoMetaPlugin_kiMJuRaTax from "/builds/OptiLynx/marketing/node_modules/.pnpm/nuxt-seo-experiments@4.0.0_magicast@0.3.4_rollup@4.20.0_vite@5.3.5_@types+node@18.19.43_62e689f114e9e49817893ee92df59563/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.js";
import plugin_bQPSrP6KxU from "/builds/OptiLynx/marketing/node_modules/.pnpm/@dargmuesli+nuxt-cookie-control@8.4.6_magicast@0.3.4_rollup@4.20.0_webpack@5.93.0_esbuild@0.23.0_/node_modules/@dargmuesli/nuxt-cookie-control/dist/runtime/plugin.js";
import slideovers_4iG0FNSHQF from "/builds/OptiLynx/marketing/node_modules/.pnpm/@nuxt+ui@2.18.3_focus-trap@7.5.4_magicast@0.3.4_rollup@4.20.0_vite@5.3.5_@types+node@18_361c87b2b81ff6046422e3a688e50436/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_tHGFZqCJyz from "/builds/OptiLynx/marketing/node_modules/.pnpm/@nuxt+ui@2.18.3_focus-trap@7.5.4_magicast@0.3.4_rollup@4.20.0_vite@5.3.5_@types+node@18_361c87b2b81ff6046422e3a688e50436/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_ZUqUO6ISGM from "/builds/OptiLynx/marketing/node_modules/.pnpm/@nuxt+ui@2.18.3_focus-trap@7.5.4_magicast@0.3.4_rollup@4.20.0_vite@5.3.5_@types+node@18_361c87b2b81ff6046422e3a688e50436/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_kMFpnxgd3E from "/builds/OptiLynx/marketing/node_modules/.pnpm/@nuxtjs+color-mode@3.4.2_magicast@0.3.4_rollup@4.20.0/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_n254ZGOwxq from "/builds/OptiLynx/marketing/node_modules/.pnpm/@nuxt+icon@1.4.5_magicast@0.3.4_rollup@4.20.0_vite@5.3.5_@types+node@18.19.43_sass@1.63_74f3d5731fde7653d7042aa7922198cb/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import switch_locale_path_ssr_EmYcWZMaP9 from "/builds/OptiLynx/marketing/node_modules/.pnpm/@nuxtjs+i18n@8.3.3_magicast@0.3.4_rollup@4.20.0_vue@3.4.35_typescript@5.1.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.mjs";
import i18n_WwZIsqqQtp from "/builds/OptiLynx/marketing/node_modules/.pnpm/@nuxtjs+i18n@8.3.3_magicast@0.3.4_rollup@4.20.0_vue@3.4.35_typescript@5.1.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import simple_lightbox_client_vigHnfRDDP from "/builds/OptiLynx/marketing/plugins/simple-lightbox.client.ts";
export default [
  revive_payload_client_92eS60c3vX,
  unhead_N5W30HGqne,
  router_hRGwEYxEnd,
  _0_siteConfig_QyOud9SEIV,
  payload_client_UU6mgFsJSp,
  navigation_repaint_client_emXItkDaLZ,
  check_outdated_build_client_RivNmP2y4E,
  chunk_reload_client_cKgY96SZQ9,
  components_plugin_KR1HBZs4kY,
  prefetch_client_FUMnFNe26i,
  titles_ejsiE6XLSp,
  defaults_HHs2JsToHg,
  siteConfig_sGgetzQO2y,
  inferSeoMetaPlugin_kiMJuRaTax,
  plugin_bQPSrP6KxU,
  slideovers_4iG0FNSHQF,
  modals_tHGFZqCJyz,
  colors_ZUqUO6ISGM,
  plugin_client_kMFpnxgd3E,
  plugin_n254ZGOwxq,
  switch_locale_path_ssr_EmYcWZMaP9,
  i18n_WwZIsqqQtp,
  simple_lightbox_client_vigHnfRDDP
]