import { default as indexf0F5ajzixKMeta } from "/builds/OptiLynx/marketing/pages/index.vue?macro=true";
import { default as design_45kitglV0NhwmVeMeta } from "/builds/OptiLynx/marketing/pages/design-kit.vue?macro=true";
import { default as ochrana_45osobnich_45udaju8mhJb9KLQFMeta } from "/builds/OptiLynx/marketing/pages/ochrana-osobnich-udaju.vue?macro=true";
import { default as component_45stubkjakKDj6T9Meta } from "/builds/OptiLynx/marketing/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@18.19.43_@upstash+redis@1.34.0_eslint@8.5_26c4a0e407f3a40accba65c907e7d1f1/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubkjakKDj6T9 } from "/builds/OptiLynx/marketing/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@18.19.43_@upstash+redis@1.34.0_eslint@8.5_26c4a0e407f3a40accba65c907e7d1f1/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/builds/OptiLynx/marketing/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "design-kit___cs",
    path: "/cs/design-kit",
    component: () => import("/builds/OptiLynx/marketing/pages/design-kit.vue").then(m => m.default || m)
  },
  {
    name: "design-kit___sk",
    path: "/sk/design-kit",
    component: () => import("/builds/OptiLynx/marketing/pages/design-kit.vue").then(m => m.default || m)
  },
  {
    name: "design-kit___en",
    path: "/en/design-kit",
    component: () => import("/builds/OptiLynx/marketing/pages/design-kit.vue").then(m => m.default || m)
  },
  {
    name: "index___cs",
    path: "/cs",
    component: () => import("/builds/OptiLynx/marketing/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___sk",
    path: "/sk",
    component: () => import("/builds/OptiLynx/marketing/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/builds/OptiLynx/marketing/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "ochrana-osobnich-udaju___cs",
    path: "/cs/ochrana-osobnich-udaju",
    component: () => import("/builds/OptiLynx/marketing/pages/ochrana-osobnich-udaju.vue").then(m => m.default || m)
  },
  {
    name: "ochrana-osobnich-udaju___sk",
    path: "/sk/ochrana-osobnich-udaju",
    component: () => import("/builds/OptiLynx/marketing/pages/ochrana-osobnich-udaju.vue").then(m => m.default || m)
  },
  {
    name: "ochrana-osobnich-udaju___en",
    path: "/en/ochrana-osobnich-udaju",
    component: () => import("/builds/OptiLynx/marketing/pages/ochrana-osobnich-udaju.vue").then(m => m.default || m)
  },
  {
    name: component_45stubkjakKDj6T9Meta?.name,
    path: "/sitemap.xml",
    component: component_45stubkjakKDj6T9
  }
]